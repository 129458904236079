import clsx from 'clsx';

import { Scrollbar } from '~components/generic/scrollbar/Scrollbar';
import useEditModules from '~components/page/EditmodeHeader/useEditModules';
import SidebarContextContainer from '~components/sidebar/context/SidebarContextContainer';
import SidebarHeaderContainer from '~components/sidebar/header/SidebarHeaderContainer';
import { SidebarMenuContainer } from '~components/sidebar/menu/SidebarMenuContainer';
import { SidebarSearch } from '~components/sidebar/search/SidebarSearch';
import styles from './SidebarContent.module.css';

export type SidebarContentProps = {
  context: string;
  editing: boolean;
  onCloseSidebar: () => void;
  hideSidebarSettings: boolean;
  sidebarWidgets: WidgetSidebar[];
  isPdfEnabled: boolean;
};

export function SidebarContent({
  context,
  onCloseSidebar,
  sidebarWidgets,
  isPdfEnabled,
  editing,
  hideSidebarSettings,
}: SidebarContentProps) {
  const { isEditing: isEditingModules } = useEditModules();
  const showSettings = editing && context && !hideSidebarSettings && !isEditingModules;
  return (
    <div className={clsx('c-sidebar__outer-content', styles.outer)}>
      <Scrollbar className="c-sidebar__scrollbar" disableHorizontalScrolling>
        <div className="c-sidebar__content">
          <div className="c-sidebar__fixed-header">
            <SidebarHeaderContainer showSettings={showSettings} entityId={context} onCloseSidebar={onCloseSidebar} />
            {!showSettings && <SidebarSearch />}
          </div>
          {showSettings && <SidebarContextContainer entityId={context} isPdfEnabled={isPdfEnabled} />}
          {!showSettings && <SidebarMenuContainer sidebarWidgets={sidebarWidgets} />}
        </div>
      </Scrollbar>
    </div>
  );
}

export default SidebarContent;
