import clsx from 'clsx';

import { Scrollbar } from '~components/generic/scrollbar/Scrollbar';
import SidebarContextContainer from '~components/sidebar/context/SidebarContextContainer';
import SidebarHeaderContainer from '~components/sidebar/header/SidebarHeaderContainer';
import styles from './RightSidebarContent.css';

export type RightSidebarContentProps = {
  context: string;
  editing: boolean;
  onCloseSidebar: () => void;
  hideSidebarSettings: boolean;
  sidebarWidgets: WidgetSidebar[];
  isPdfEnabled: boolean;
};

export function RightSidebarContent({ context, editing, hideSidebarSettings, isPdfEnabled }: RightSidebarContentProps) {
  // if (!showSettings) {
  //   return null;
  // }

  return (
    <div
      className={clsx(
        'c-sidebar u-size-sm u-overflow-hidden',
        styles.rightSidebar,
        editing && context && !hideSidebarSettings && styles.rightSidebarVisible,
      )}
    >
      <div className={clsx('c-sidebar__outer-content', styles.outer)}>
        <Scrollbar className="c-sidebar__scrollbar" disableHorizontalScrolling>
          <div className="c-sidebar__content">
            <SidebarHeaderContainer fieldName="relationships.field_modules.data" entityId={context} />
            <SidebarContextContainer entityId={context} isPdfEnabled={isPdfEnabled} />
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

export default RightSidebarContent;
